// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kz-js": () => import("./../../../src/pages/kz.js" /* webpackChunkName: "component---src-pages-kz-js" */),
  "component---src-pages-ua-js": () => import("./../../../src/pages/ua.js" /* webpackChunkName: "component---src-pages-ua-js" */),
  "component---src-pages-uz-js": () => import("./../../../src/pages/uz.js" /* webpackChunkName: "component---src-pages-uz-js" */)
}

